<template>
  <div>
    <b-field label="Currency">
      <b-select v-model="invoice.currency" :disabled="disabled">
        <option v-for="c in currencies" :key="c.key" :value="c.key">
          {{ c.label }} ({{ c.prefix || c.suffix }})
        </option>
      </b-select>
    </b-field>

    <b-field label="Invoice items">
      <transition-group name="list" tag="div">
        <div
          v-for="(item, index) in invoice.items"
          :key="`item-${index}`"
          class="columns has-margin-bottom-50"
        >
          <div class="column">
            <b-input
              v-model="item.description"
              :disabled="disabled"
              placeholder="eg. Software license"
            />
          </div>
          <div class="column" :style="{ maxWidth: '160px' }">
            <b-input
              v-model.number="item.price"
              type="number"
              min="0"
              step="0.01"
              placeholder="Price"
              :disabled="disabled"
            />
          </div>
          <div class="column" :style="{ maxWidth: '60px' }">
            <button
              class="button is-medium is-outlined"
              :class="{
                'has-text-danger': !isLast(index),
                'has-text-grey-light': isLast(index)
              }"
              :disabled="isLast(index)"
              @click="invoice.items.splice(index, 1)"
            >
              <b-icon icon="trash-alt" size="is-small" />
            </button>
          </div>
        </div>
      </transition-group>
    </b-field>
  </div>
</template>

<script>
const currencies = require("@/data/currencies.json");
export default {
  name: "ManualInvoice",
  props: {
    userId: {
      required: true,
      type: String
    },
    currency: {
      type: String,
      default: "gbp"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invoice: {
        currency: this.currency,
        userId: this.userId,
        items: []
      }
    };
  },
  computed: {
    currencies() {
      return this.$_(currencies)
        .map((c, key) => {
          c["key"] = key;
          return c;
        })
        .orderBy(["code"], ["asc"])
        .value();
    },
    touchedItems() {
      return this.$_.map(this.invoice.items, item => {
        const isTouched = !!(
          `${item.description}`.length + `${item.price}`.length
        );
        return isTouched ? 1 : 0;
      });
    }
  },
  watch: {
    touchedItems(items) {
      const last2 = this.$_.takeRight(items, 2);
      const pattern = `${last2.length}-${this.$_.sum(last2)}`;
      if (["1-1", "2-2"].includes(pattern)) {
        this.addInvoiceItem();
      } else if (["2-0"].includes(pattern)) {
        this.removeLastItem();
      }
    },
    invoice: {
      handler() {
        const invoice = this.$_.cloneDeep(this.invoice);
        invoice.items.pop();
        this.$emit("invoice", invoice);
      },
      deep: true
    }
  },
  created() {
    this.addInvoiceItem();
  },
  methods: {
    addInvoiceItem() {
      this.invoice.items.push({
        description: "",
        price: ""
      });
    },
    removeLastItem() {
      this.invoice.items.pop();
    },
    isLast(index) {
      return index + 1 === this.invoice.items.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
